.barsMenu {
  margin: 8px;
  padding: 0 6px 24px 6px;
  color: #aaa;
  border: none;
  box-shadow: none;
  position: fixed;
  top: 0;
}

.barsBtn,
.barsBtn:before,
.barsBtn:after {
  background: #aaa;
}

.menuCon {
  float: right;
  width: 100%;
}

nav {
  min-height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-links {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.nav-links li {
  margin: 1rem auto 0;
  padding: 0 2rem;
  text-decoration: none;
}

.nav-links li a {
  color: #121212;
}

.nav-links li:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .menuBar {
    height: 50px;
  }
  .barsMenu {
    padding: 3px !important;
    margin-left: 80vw;
  }
}
