.project-container {
  min-height: 96vh;
}

.header {
  text-align: center;
  margin: 10px;
}

.btns-tags {
  margin: 5px 0;
}
.ant-collapse-header {
  margin: 5px auto;
}
.ant-collapse-content-box {
  padding-top: 0 !important;
}

.project-card {
  position: relative;
  border: none;
  border-radius: 5px;
  box-shadow: 0 13px 8px -10px rgba(0, 0, 0, 0.1);
  margin: 15px 30px;
  overflow: hidden;
  p {
    color: #444;
    font-size: 1rem;
    line-height: 1.2rem;
    margin-bottom: 0.3rem;
  }
  .tagline {
    color: #333;
    font-size: 1.2rem;
  }
  .card-img-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0;
    background: rgba(#333, 0.9);
    transition: 300ms ease-in-out;
    .project-details {
      padding: 30px;
      .project-layer-desc,
      .project-layer-title {
        opacity: 0;
        transform: scale(0.5);
        transition: 300ms ease-in-out;
      }
    }
  }
  .img-container {
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .card-img {
    height: 100%;
    position: relative;
    transition: 300ms ease-in-out;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .card-content {
    padding: 15px 15px 0;
    z-index: 10;
  }
}

.ant-card-body {
  padding: 0;
}

.card-img {
  width: 100%;
  height: auto;
}

.projects {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.project-name {
  font-size: 1.75rem;
  margin-bottom: 0.3em;
  line-height: 1.2em;
  font-weight: 500;
}
.card-content {
  margin: 10px;
}

.btn-tag {
  background-color: #fafafa;
  border: 1px solid #454545;
  border-radius: 25px;
  margin: 3px 1px;
  padding: 3px 10px;
}

.btn-tag-selected {
  background-color: #343434;
  color: #e3e3e3;
  border: 1px solid #343434;
  border-radius: 25px;
  margin: 3px 1px;
  padding: 3px 10px;
}

@media (max-width: 767px) {
  .project-card {
    margin: 10px 0;
    .project-name {
      font-size: 1.6rem;
    }
    .tagline {
      font-size: 1rem;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .project-card {
    margin: 5px;
  }
}
