@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  font-family: "Lato", sans-serif !important;
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

.App {
  width: 100%;
  background: #fafafa;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  margin: 10vh auto 5vh;
  min-height: 81vh;
}

.ant-layout {
  background: #fff !important;
}

.menuBar {
  width: 100%;
  height: auto;
  padding: 0 20px;
  border-bottom: solid 1px #e8e8e8;
  overflow: visible;
  background: #fff;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 30;
}

.leftMenu {
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo {
  width: 150px;
  float: left;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  padding: 19px 20px;
  text-transform: capitalize;
}

.menuCon {
  width: calc(100% - 150px);
  float: left;
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .leftMenu {
  float: left;
}

.menuCon .rightMenu {
  float: right;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: #1890ff;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #1890ff;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
  }
  .leftMenu,
  .rightMenu {
    display: none;
  }
  .logo a {
    margin-left: -20px;
  }
  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }
  .logo a {
    padding: 10px 20px;
  }
}

@media (min-width: 1200px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
  }
}

.barsMenu {
  margin: 8px;
  padding: 0 6px 24px 6px;
  color: #aaa;
  border: none;
  box-shadow: none;
  position: fixed;
  top: 0;
}

.barsBtn,
.barsBtn:before,
.barsBtn:after {
  background: #aaa;
}

.menuCon {
  float: right;
  width: 100%;
}

nav {
  min-height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-links {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.nav-links li {
  margin: 1rem auto 0;
  padding: 0 2rem;
  text-decoration: none;
}

.nav-links li a {
  color: #121212;
}

.nav-links li:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .menuBar {
    height: 50px;
  }
  .barsMenu {
    padding: 3px !important;
    margin-left: 80vw;
  }
}

.title {
  color: #000;
  font-size: 6em;
  margin: 6vh auto 0;
}

.section {
  min-height: 100vh;
  padding: 50px 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro-card {
  margin: 15px;
  border-radius: 5px;
  padding: 15px;
  border-radius: 10px;
}
.ant-card {
  font-size: 1rem;
}
.ant-btn {
  font-size: 1rem;
  height: auto;
  padding: 10px;
  border-radius: 5px;
}
.desc {
  color: #000;
  text-align: center;
  font-size: 1rem;
}

.bg:after {
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.landing-btn {
  display: none;
  width: 100%;
  margin: 5px 15px;
}
.check-project-button {
  background: #3e3e3e;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  color: #fdfdfd;
  height: 40px;
}
.check-project-button:hover {
  cursor: pointer;
  border: 2px solid #121212;
}

@media (max-width: 767px) {
  p {
    font-size: 0.9rem;
  }
  .job-title {
    font-size: 1rem;
    text-align: center;
  }
  .job-desc {
    text-align: center;
  }
  .intro-card {
    margin: 3px;
  }
  .intro-card-title {
    font-size: 1.2rem;
    margin: 3px;
  }
  .ant-divider-horizontal {
    margin: 3px 0;
  }
  .section {
    padding: 5px;
  }
  .title {
    color: #000;
    font-size: 2.25em;
    margin: 5vh auto 0;
  }
  .desc {
    color: #000;
    margin: 15px auto;
    font-size: 1.2em;
  }
  .col-landing {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .landing-btn {
    outline: none;
    text-decoration: underline;
    color: #000;
    font-size: 1.25em;
    text-align: center;
    border: none;
    display: inherit;
    margin: 5px auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .content {
    margin: 10vh 0 5vh;
  }
  .section {
    padding: 50px 0;
  }
}

/* iPad landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .title {
    margin: 0 auto;
  }
}

.tags {
  font-size: 0.9rem;
  margin: 10px 0 5px;
}

.tag {
  background: #eee;
  border-radius: 3px;
  padding: 0 2px;
}

.tag-icon {
  margin-right: 2px;
}

.project-container {
  min-height: 96vh; }

.header {
  text-align: center;
  margin: 10px; }

.btns-tags {
  margin: 5px 0; }

.ant-collapse-header {
  margin: 5px auto; }

.ant-collapse-content-box {
  padding-top: 0 !important; }

.project-card {
  position: relative;
  border: none;
  border-radius: 5px;
  box-shadow: 0 13px 8px -10px rgba(0, 0, 0, 0.1);
  margin: 15px 30px;
  overflow: hidden; }
  .project-card p {
    color: #444;
    font-size: 1rem;
    line-height: 1.2rem;
    margin-bottom: 0.3rem; }
  .project-card .tagline {
    color: #333;
    font-size: 1.2rem; }
  .project-card .card-img-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0;
    background: rgba(51, 51, 51, 0.9);
    transition: 300ms ease-in-out; }
    .project-card .card-img-layer .project-details {
      padding: 30px; }
      .project-card .card-img-layer .project-details .project-layer-desc,
      .project-card .card-img-layer .project-details .project-layer-title {
        opacity: 0;
        transform: scale(0.5);
        transition: 300ms ease-in-out; }
  .project-card .img-container {
    height: 100%;
    position: relative;
    overflow: hidden; }
  .project-card .card-img {
    height: 100%;
    position: relative;
    transition: 300ms ease-in-out;
    background-repeat: no-repeat;
    background-size: cover; }
  .project-card .card-content {
    padding: 15px 15px 0;
    z-index: 10; }

.ant-card-body {
  padding: 0; }

.card-img {
  width: 100%;
  height: auto; }

.projects {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }

.project-name {
  font-size: 1.75rem;
  margin-bottom: 0.3em;
  line-height: 1.2em;
  font-weight: 500; }

.card-content {
  margin: 10px; }

.btn-tag {
  background-color: #fafafa;
  border: 1px solid #454545;
  border-radius: 25px;
  margin: 3px 1px;
  padding: 3px 10px; }

.btn-tag-selected {
  background-color: #343434;
  color: #e3e3e3;
  border: 1px solid #343434;
  border-radius: 25px;
  margin: 3px 1px;
  padding: 3px 10px; }

@media (max-width: 767px) {
  .project-card {
    margin: 10px 0; }
    .project-card .project-name {
      font-size: 1.6rem; }
    .project-card .tagline {
      font-size: 1rem; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .project-card {
    margin: 5px; } }

