@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap");
html,
body {
  font-family: "Lato", sans-serif !important;
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

.App {
  width: 100%;
  background: #fafafa;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  margin: 10vh auto 5vh;
  min-height: 81vh;
}

.ant-layout {
  background: #fff !important;
}

.menuBar {
  width: 100%;
  height: auto;
  padding: 0 20px;
  border-bottom: solid 1px #e8e8e8;
  overflow: visible;
  background: #fff;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 30;
}

.leftMenu {
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo {
  width: 150px;
  float: left;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  padding: 19px 20px;
  text-transform: capitalize;
}

.menuCon {
  width: calc(100% - 150px);
  float: left;
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .leftMenu {
  float: left;
}

.menuCon .rightMenu {
  float: right;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: #1890ff;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #1890ff;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
  }
  .leftMenu,
  .rightMenu {
    display: none;
  }
  .logo a {
    margin-left: -20px;
  }
  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }
  .logo a {
    padding: 10px 20px;
  }
}

@media (min-width: 1200px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
  }
}
