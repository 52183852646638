.title {
  color: #000;
  font-size: 6em;
  margin: 6vh auto 0;
}

.section {
  min-height: 100vh;
  padding: 50px 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro-card {
  margin: 15px;
  border-radius: 5px;
  padding: 15px;
  border-radius: 10px;
}
.ant-card {
  font-size: 1rem;
}
.ant-btn {
  font-size: 1rem;
  height: auto;
  padding: 10px;
  border-radius: 5px;
}
.desc {
  color: #000;
  text-align: center;
  font-size: 1rem;
}

.bg:after {
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.landing-btn {
  display: none;
  width: 100%;
  margin: 5px 15px;
}
.check-project-button {
  background: #3e3e3e;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  color: #fdfdfd;
  height: 40px;
}
.check-project-button:hover {
  cursor: pointer;
  border: 2px solid #121212;
}

@media (max-width: 767px) {
  p {
    font-size: 0.9rem;
  }
  .job-title {
    font-size: 1rem;
    text-align: center;
  }
  .job-desc {
    text-align: center;
  }
  .intro-card {
    margin: 3px;
  }
  .intro-card-title {
    font-size: 1.2rem;
    margin: 3px;
  }
  .ant-divider-horizontal {
    margin: 3px 0;
  }
  .section {
    padding: 5px;
  }
  .title {
    color: #000;
    font-size: 2.25em;
    margin: 5vh auto 0;
  }
  .desc {
    color: #000;
    margin: 15px auto;
    font-size: 1.2em;
  }
  .col-landing {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .landing-btn {
    outline: none;
    text-decoration: underline;
    color: #000;
    font-size: 1.25em;
    text-align: center;
    border: none;
    display: inherit;
    margin: 5px auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .content {
    margin: 10vh 0 5vh;
  }
  .section {
    padding: 50px 0;
  }
}

/* iPad landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .title {
    margin: 0 auto;
  }
}
