.tags {
  font-size: 0.9rem;
  margin: 10px 0 5px;
}

.tag {
  background: #eee;
  border-radius: 3px;
  padding: 0 2px;
}

.tag-icon {
  margin-right: 2px;
}
